import { lazy, Suspense, useEffect } from "react";

// material ui
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./lib/theme";

// react-toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// react-helmet
import { HelmetProvider } from "react-helmet-async";

// i18n
import "./lib/i18n";

// axios lib
import "./lib/axiosLib";

// react-router-dom
import { BrowserRouter, Routes, Route } from "react-router-dom";

// react-redux
import { useDispatch, useSelector } from "react-redux";
import { updateSmartList } from "./utils/reduxSlice/smartListSlice";
import { updateUserDetail } from "./utils/reduxSlice/userSlice";
import { updateLanguageList } from "./utils/reduxSlice/languageListSlice";

// Error Boundary import
import ErrorBoundary from "./components/ErrorBoundary";

// Layout import
import UserLayout from "./components/UserLayout";
import GenericLayout from "./components/GenericLayout";
import PersonalLoanLayout from "./components/user/PersonalLoanLayout";
import BusinessLoanLayout from "./components/user/BusinessLoanLayout";
import InsuranceLayout from "./components/user/InsuranceLayout";
import MuthootGoldLayout from "./components/user/MuthootGoldLayout";
import CreditCardLayout from "./components/user/CreditCardLayout";
import GenericAdminLayout from "./components/admin/GenericAdminLayout";
import AdminLayout from "./components/admin/AdminLayout";

// ScrollToTop import
import ScrollToTop from "./components/ScrollToTop";

// GoogleAnalytics import
import GoogleAnalytics from "./components/GoogleAnalytics";

// hooks import
import smartListApi from "./hooks/GET/smartList";
import userDetailApi from "./hooks/GET/userDetail";
import languageListApi from "./hooks/POST/languageList";

// utils import
import {
  isAdminRoute,
  getJWTTokenFromCookie,
  getProspectAppIdFromCookie,
  isPartnerRoute,
  saveAppIdInCookie,
  isPrimaryDomain,
} from "./utils";

// config values import
import { enableGoogleAnalytics } from "./config";
import UserIdleTimer from "./components/UserIdleTimer";

import SuspenseLoader from "./components/SuspenseLoader";
import WhiteLabelVersion from "./components/WhiteLabelVersion";
import GenericPartnerLayout from "./components/partner/GenericPartnerLayout";
import PartnerLayout from "./components/partner/PartnerLayout";
import getPartnerIDByPartnerName from "./hooks/GET/getPartnerIDByPartnerName";
import BusinessWelcomeBack from "./pages/BusinessWelcomeBack";
import ApplicationStatusFileUploader from "./pages/admin/ApplicationStatusFileUploader";
import Lenders from "./pages/admin/Lenders";

const BannersAndCreatives = lazy(() => import("./pages/newPartner/BannersAndCreatives"));
const MessagingContent = lazy(() => import("./pages/newPartner/MessagingContent"));

const DSAQueries = lazy(() => import("./pages/newPartner/DSAQueries"));
const CustomerQueries = lazy(() => import("./pages/newPartner/CustomerQueries"));

const TrainingMaterial = lazy(() =>
  import("./pages/newPartner/TrainingMaterial")
);
const FAQ = lazy(() => import("./pages/newPartner/FAQ"));
const Communication = lazy(() => import("./pages/newPartner/Communication"));
const MarketingSupport = lazy(() =>
  import("./pages/newPartner/MarketingSupport")
);
const Insurance = lazy(() => import("./pages/newPartner/Insurance"));
const CreditCard = lazy(() => import("./pages/newPartner/CreditCard"));
const HomeLoan = lazy(() => import("./pages/newPartner/HomeLoan"));
const GoldLoan = lazy(() => import("./pages/newPartner/GoldLoan"));
const BussinessLoan = lazy(() => import("./pages/newPartner/BussinessLoan"));
const PersonalLoan = lazy(() => import("./pages/newPartner/PersonalLoan"));

// pages import
const CreateMerchantForm = lazy(() => import("./pages/CreateMerchantForm"));
const SolarLago = lazy(() => import("./pages/SolarLago"));
const FBOnboarding = lazy(() => import("./pages/FBOnboarding"));
const Home = lazy(() => import("./pages/Home"));
const ProductRedirect = lazy(() => import("./pages/ProductRedirect"));
const Custom404 = lazy(() => import("./pages/404"));
const Verification = lazy(() => import("./pages/Verification"));
const Products = lazy(() => import("./pages/Products"));
const CreditCards = lazy(() => import("./pages/CreditCards"));
const UserDetail = lazy(() => import("./pages/UserDetail"));
const BusinessDetail = lazy(() => import("./pages/BusinessDetail"));
const Offers = lazy(() => import("./pages/Offers"));
const BusinessOffers = lazy(() => import("./pages/BusinessOffers"));
const CashE = lazy(() => import("./pages/CashE"));
const MoneyView = lazy(() => import("./pages/MoneyView"));
const Upwards = lazy(() => import("./pages/Upwards"));
const WelcomeBack = lazy(() => import("./pages/WelcomeBack"));
const LenderSubmit = lazy(() => import("./pages/LenderSubmit"));
const CustomerSite = lazy(() => import("./pages/CustomerSite"));
const LendersTransaction = lazy(() => import("./pages/LendersTransaction"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./pages/TermsOfUse"));
const Transfer = lazy(() => import("./pages/Transfer"));
const MoneyTap = lazy(() => import("./pages/MoneyTap"));
const Nira = lazy(() => import("./pages/Nira"));
const KreditBee = lazy(() => import("./pages/KreditBee"));
const Finzy = lazy(() => import("./pages/Finzy"));
const EarlySalary = lazy(() => import("./pages/EarlySalary"));
const MuthootGold = lazy(() => import("./pages/MuthootGold"));
const IndiaGold = lazy(() => import("./pages/IndiaGold"));
const EarlySalaryDeclined = lazy(() => import("./pages/EarlySalaryDeclined"));
const Transaction = lazy(() => import("./pages/user/insurance/Transaction"));

const AdminLogin = lazy(() => import("./pages/admin/Login"));
const AdminDashboard = lazy(() => import("./pages/admin/Dashboard"));
const AdminMisFile = lazy(() => import("./pages/admin/MisFile"));
const AdminUserManagement = lazy(() => import("./pages/admin/UserManagement"));
const AdminMerchantManagement = lazy(() =>
  import("./pages/admin/MerchantManagement")
);
const ProductsReport = lazy(() => import("./pages/admin/ProductsReport"));
const CommissionUploadFile = lazy(() =>
  import("./pages/admin/CommissionUploadFile")
);
const CustomersJourney = lazy(() => import("./pages/admin/CustomersJourney"));
const PartnerLogin = lazy(() => import("./pages/partner/Login"));
const TrackingLinks = lazy(() => import("./pages/admin/TrackingLinks"));
const AdminCreditCards = lazy(() => import("./pages/admin/CreditCards"));
const Messaging = lazy(() => import("./pages/admin/Messaging"));
const Telecalling = lazy(() => import("./pages/admin/Telecalling"));

const ProductsReportVendors = lazy(() =>
  import("./pages/admin/ProductsReportVendors")
);
const CustomerInfoDeletion = lazy(() =>
  import("./pages/admin/CustomerInfoDeletion")
);
const LendersOnOff = lazy(() => import("./pages/admin/LendersOnOff"));
const CustomerEligibility = lazy(() =>
  import("./pages/admin/CustomerEligibility")
);
const InternalReport = lazy(() => import("./pages/admin/InternalReport"));
const Partners = lazy(() => import("./pages/admin/Partners"));
const CSRPortal = lazy(() => import("./pages/admin/CSRPortal"));
const CSRClientProfile = lazy(() => import("./pages/admin/CSRClientProfile"));

// Partner Portal
// const PartnerDashboard = lazy(() => import("./pages/partner/Dashboard"));
const PartnerDashboard = lazy(() => import("./pages/newPartner/Dashboard"));
const PartnerReports = lazy(() => import("./pages/partner/Reports"));
const PartnerCustomFunnel = lazy(() => import("./pages/partner/CustomFunnel"));
const PartnerTrackingLinks = lazy(() =>
  import("./pages/partner/TrackingLinks")
);
const PartnerSettings = lazy(() => import("./pages/partner/Settings"));
const VouchaAuth = lazy(() => import("./pages/VouchaAuth"));
const IndialendsOTP = lazy(() => import("./pages/IndialendsOTP"));
const IndialendsOTPApp = lazy(() => import("./pages/IndialendsOTPApp"));
// const BankDetails = lazy(() => import("./pages/partner/BankDetails"));

const ProductOffering = lazy(() =>
  import("./pages/newPartner/ProductOffering")
);

function App() {
  const dispatch = useDispatch();

  // getting user detail start
  const fetchUserDtlTrg = useSelector((state) => state.temp.fetchUserDtlTrg);

  useEffect(() => {
    if (!isAdminRoute() && !isPartnerRoute()) {
      const prospectAppId = getProspectAppIdFromCookie();
      const authToken = getJWTTokenFromCookie();
      if (prospectAppId && authToken) {
        userDetailApi(prospectAppId)
          .then((data) => {
            if (data.inflight_credit_app_id) {
              saveAppIdInCookie(data.inflight_credit_app_id);
            }
            dispatch(updateUserDetail(data));
          })
          .catch((error) => console.log(error));
      }
    }
  }, [fetchUserDtlTrg]);
  // getting user detail end

  // getting smart list start
  // useEffect(() => {
  //   let partnerId = null;
  //   if (isAdminRoute()) {
  //     partnerId = 0;
  //   } else if(isPartnerRoute()) {}
  useEffect(() => {
    async function init() {
      let partnerId = null;
      if (isAdminRoute()) {
        partnerId = 0;
      } else {
        if (process.env.REACT_APP_PARTNER_API_NAME !== "l") {
          await getPartnerIDByPartnerName(
            process.env.REACT_APP_PARTNER_API_NAME
          ).then((data) => {
            partnerId = data.id;
          });
        }
      }
      await smartListApi(partnerId)
        .then((data) => dispatch(updateSmartList(data)))
        .catch((error) => console.log(error));
    }
    init();
  }, []);
  // getting smart list end

  // getting langauge list start
  useEffect(() => {
    languageListApi()
      .then((data) => dispatch(updateLanguageList(data)))
      .catch((error) => console.log(error));
  }, []);
  // getting langauge list end

  return (
    <ThemeProvider theme={theme(false)}>
      <CssBaseline />
      <ErrorBoundary>
        <ToastContainer limit={5} autoClose={3000} />
        <UserIdleTimer />
        <HelmetProvider>
          <BrowserRouter>
            <Suspense fallback={<SuspenseLoader />}>
              <ScrollToTop />
              {enableGoogleAnalytics && <GoogleAnalytics />}
              <Routes>
                <Route path="indialends-otp" element={<IndialendsOTP />} />

                {/* <Route path="/" element={<Navigate to="/cl" />} /> */}
                {/* user generic(non-loggedin) routes */}
                <Route
                  exact
                  path="create-merchant-form/"
                  element={<CreateMerchantForm />}
                />
                {window.location.hostname.includes("solar.creditlinks.in") && (
                  <Route exact path="" element={<SolarLago />} />
                )}
                <Route
                  exact
                  path="socialmedia-onboarding"
                  element={<FBOnboarding />}
                />
                <Route
                  exact
                  path="create-merchant-form/:partner_name"
                  element={<CreateMerchantForm />}
                />
                <Route exact path="auth" element={<VouchaAuth />} />
                <Route exact path="redirect" element={<ProductRedirect />} />
                <Route element={<WhiteLabelVersion />}>
                  <Route exact path="/" element={<GenericLayout />}>
                    <Route
                      exact
                      path=""
                      element={isPrimaryDomain ? <Products /> : <Home />}
                    />
                    <Route
                      exact
                      path=":urlSegment"
                      element={isPrimaryDomain ? <Products /> : <Home />}
                    />
                    {isPrimaryDomain && (
                      <Route exact path="login" element={<Home />} />
                    )}
                    <Route
                      exact
                      path="verification"
                      element={<Verification />}
                    />
                    {!isPrimaryDomain && (
                      <Route exact path="products" element={<Products />} />
                    )}
                    <Route
                      exact
                      path="credit-cards"
                      element={<CreditCards />}
                    />
                    <Route
                      exact
                      path="privacy-policy"
                      element={<PrivacyPolicy />}
                    />
                    <Route exact path="terms-of-use" element={<TermsOfUse />} />
                  </Route>

                  {/* user authorized(loggedin) routes */}
                  {/* Personal Loan */}
                  <Route exact path="/user" element={<UserLayout />}>
                    <Route element={<PersonalLoanLayout />}>
                      <Route path="user-detail" element={<UserDetail />} />
                      <Route path="offers" element={<Offers />} />
                      <Route path="cashe" element={<CashE />} />
                      <Route path="money-view" element={<MoneyView />} />
                      <Route path="upwards" element={<Upwards />} />
                      <Route path="money-tap" element={<MoneyTap />} />
                      <Route path="nira" element={<Nira />} />
                      <Route path="kreditbee" element={<KreditBee />} />
                      <Route path="finzy" element={<Finzy />} />
                      <Route path="earlysalary" element={<EarlySalary />} />
                      <Route
                        path="earlysalary/declined"
                        element={<EarlySalaryDeclined />}
                      />
                      <Route
                        path="lenders-transaction/:lender"
                        element={<LendersTransaction />}
                      />
                      <Route
                        path="indialends-otp"
                        element={<IndialendsOTPApp />}
                      />
                      <Route path="welcome-back" element={<WelcomeBack />} />
                      <Route path="lender-submit" element={<LenderSubmit />} />
                    </Route>

                    <Route element={<BusinessLoanLayout />}>
                      <Route
                        path="business-detail"
                        element={<BusinessDetail />}
                      />
                      <Route
                        path="business-welcome-back"
                        element={<BusinessWelcomeBack />}
                      />
                      <Route
                        path="business-offers"
                        element={<BusinessOffers />}
                      />
                    </Route>

                    <Route element={<MuthootGoldLayout />}>
                      <Route path="muthootgold" element={<MuthootGold />} />
                      <Route path="indiagold" element={<IndiaGold />} />
                    </Route>

                    {/* Insurance */}
                    <Route path="insurance" element={<InsuranceLayout />}>
                      <Route path="transaction" element={<Transaction />} />
                    </Route>

                    {/* Credit card */}
                    <Route
                      path="credit-card"
                      element={<CreditCardLayout />}
                    ></Route>
                  </Route>
                </Route>
                <Route exact path="customer-site" element={<CustomerSite />} />

                {/* admin generic(non-loggedin) routes */}
                <Route exact path="/admin" element={<GenericAdminLayout />}>
                  <Route exact path="login" element={<AdminLogin />} />
                </Route>

                {/* admin authorized(loggedin) routes */}
                <Route exact path="/admin" element={<AdminLayout />}>
                  <Route exact path="dashboard" element={<AdminDashboard />} />
                  <Route exact path="mis-upload" element={<AdminMisFile />} />
                  <Route
                    exact
                    path="user-management"
                    element={<AdminUserManagement />}
                  />
                  <Route
                    path="customer-info-deletion"
                    element={<CustomerInfoDeletion />}
                  />
                  <Route path="vendors-on-off" element={<LendersOnOff />} />
                  <Route
                    path="customer-eligibility"
                    element={<CustomerEligibility />}
                  />
                  <Route
                    exact
                    path="internal-report"
                    element={<InternalReport />}
                  />
                  <Route exact path="partners" element={<Partners />} />
                  <Route
                    exact
                    path="products-report"
                    element={<ProductsReport />}
                  />
                  <Route
                    exact
                    path="vendors-products-report"
                    element={<ProductsReportVendors />}
                  />
                  <Route
                    exact
                    path="customers-journey"
                    element={<CustomersJourney />}
                  />
                  <Route
                    exact
                    path="merchant-management"
                    element={<AdminMerchantManagement />}
                  />
                  <Route
                    exact
                    path="tracking-links"
                    element={<TrackingLinks />}
                  />
                  <Route exact path="csr-portal" element={<CSRPortal />} />
                  <Route
                    exact
                    path="csr-portal/:id/:pid"
                    element={<CSRClientProfile />}
                  />
                  <Route
                    exact
                    path="commission-upload-file"
                    element={<CommissionUploadFile />}
                  />
                  <Route
                    exact
                    path="application-status-file"
                    element={<ApplicationStatusFileUploader />}
                  />
                  <Route exact path="lenders" element={<Lenders />} />
                  <Route
                    exact
                    path="credit-cards"
                    element={<AdminCreditCards />}
                  />
                  <Route exact path="messaging" element={<Messaging />} />
                  <Route exact path="telecalling" element={<Telecalling />} />
                </Route>
                <Route
                  exact
                  path="/transfer/:prospectId"
                  element={<Transfer />}
                />

                {/* admin generic(non-loggedin) routes */}
                <Route exact element={<GenericPartnerLayout />}>
                  <Route
                    exact
                    path="/:partner/partner/login"
                    element={<PartnerLogin />}
                  />
                  <Route
                    exact
                    path="/partner/login"
                    element={<PartnerLogin />}
                  />
                </Route>

                <Route exact path="/partner" element={<PartnerLayout />}>
                  <Route
                    exact
                    path="dashboard"
                    element={<PartnerDashboard />}
                  />
                  <Route
                    exact
                    path="dashboard/product-offering"
                    element={<ProductOffering />}
                  />
                  <Route
                    exact
                    path="dashboard/product-offering/personal-loan"
                    element={<PersonalLoan />}
                  />
                  <Route
                    exact
                    path="dashboard/product-offering/business-loan"
                    element={<BussinessLoan />}
                  />
                  <Route
                    exact
                    path="dashboard/product-offering/gold-loan"
                    element={<GoldLoan />}
                  />
                  <Route
                    exact
                    path="dashboard/product-offering/home-loan"
                    element={<HomeLoan />}
                  />
                  <Route
                    exact
                    path="dashboard/product-offering/credit-card"
                    element={<CreditCard />}
                  />
                  <Route
                    exact
                    path="dashboard/product-offering/insurance"
                    element={<Insurance />}
                  />

                  <Route
                    exact
                    path="dashboard/marketing-support"
                    element={<MarketingSupport />}
                  />
                  <Route
                    exact
                    path="dashboard/marketing-support/messaging-content"
                    element={<MessagingContent />}
                  />
                  <Route
                    exact
                    path="dashboard/marketing-support/banners-and-creatives"
                    element={<BannersAndCreatives />}
                  />

                  <Route
                    exact
                    path="dashboard/communication"
                    element={<Communication />}
                  />
                  <Route exact path="dashboard/faqs" element={<FAQ />} />

                  <Route exact path="dashboard/faqs/customer-queries"
                    element={<CustomerQueries />}
                  />
                  <Route exact path="dashboard/faqs/dsa-queries"
                    element={<DSAQueries />}
                  />
                  <Route
                    exact
                    path="dashboard/training-material"
                    element={<TrainingMaterial />}
                  />
                  {/* <Route exact path="bank-details" element={<BankDetails />} /> */}
                  <Route exact path="reports" element={<PartnerReports />} />

                  <Route
                    exact
                    path="customFunnel"
                    element={<PartnerCustomFunnel />}
                  />
                  <Route
                    exact
                    path="tracking-links"
                    element={<PartnerTrackingLinks />}
                  />
                  <Route exact path="settings" element={<PartnerSettings />} />
                </Route>

                {/* Page not found routes */}
                <Route exact path="/404" element={<Custom404 />} />
                <Route path="*" element={<Custom404 />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </HelmetProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
